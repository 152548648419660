@import './fonts';

// nb-layout {
//     padding-top: 0 !important;
// }
// nb-sidebar {
//     .main-container {
//         position: fixed;
//         background: #fff;
//     }
// }
// // .nb-theme-default nb-layout.with-scroll .scrollable-container {
// //     height: auto !important;
// // }
// // body {
// //     height: auto !important;
// //     overflow: initial !important;
// //     overflow-x: hidden !important;
// // }
// // nb-menu {
// //     ul.menu-items {
// //         position: relative;
// //         height: 100vh;
// //        .menu-item:nth-last-of-type(1) {
// //         position: fixed;
// //         bottom: 6px;
// //         width: 100%;
// //         }
// //     }
// // }
// .main-container.main-container-fixed {
//     z-index: 5;
// }
// .scrollable-container {
//     width: 100% !important;
//     max-width: 100% !important;
//     margin: 0 !important;
// }
// .nb-theme-default nb-layout.window-mode .scrollable-container {
//     max-width: max-content;
// }
// .nb-checkbox {
//     label {
//         width: max-content !important;
//         padding: 0 !important;
//     }
//     .native-input {
//         cursor: pointer;
//     }
// }
// nb-icon.modal-icons {
//     width: 24px !important;
//     height: 24px !important;
//     color: #8F9BB3 !important;
// }
// nb-icon.modal-icons {
//     &.address-icon {
//         width: 16px !important;
//         height: 16px !important;
//     }
// }
// nb-icon.warning-icon {
//     cursor: pointer;
//     color: #FFAA00;
// }
// nb-icon.done-mark {
//     color: #00D68F;
// }
// nb-icon.arrow-donward {
//     width: 18px;
//     height: 18px;
//     transition: all 0.3s;
//     &.arrow-donward--open {
//         transform: rotate(180deg);
//     }
// }
// nb-icon.error-icons {
//     width: 16px !important;
//     height: 16px !important;
//     margin-right: 5px;
// }
// .cdk-overlay-pane {
//     box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
//     border-style: solid;
//     border-width: 0;
//     overflow: hidden;
// }
// .ngx-selects nb-select {
//     width: 100% !important;
//     max-width: 100% !important;
//     height: 48px !important;
//     &.error {
//         .select-button {
//             border: 1px solid red !important;
//         }
//     }
//     .select-button {
//         height: 100% !important;
//         padding-left: 16px !important;
//         padding-right: 12px !important;
//         font-size: 15px !important;
//         line-height: 20px !important;
//         &:active {
//             outline: none !important;
//             box-shadow: none !important;
//         }
//         &:focus {
//             outline: none !important;
//             box-shadow: none !important;
//         }
//     }
//     nb-icon {
//         svg {
//             position: absolute;
//             width: 34px;
//             height: 34px;
//             top: -4px;
//             left: -7px;
//         }
//         path {
//             fill: #8F9BB3;
//         }
//     }
// }
// .cdk-overlay-pane {
//     overflow-y: auto;
// }
// .nb-theme-default nb-checkbox .native-input:focus+.custom-checkbox {
//     box-shadow: none !important;
// }
// .btn {
//     border-radius: 4px;
//     font-family: 'Open Sans';
//     font-style: normal;
//     font-weight: bold;
//     font-size: 14px;
//     line-height: 16px;
//     text-align: center;
//     background-color: transparent;
//     cursor: pointer;
//     border: none;
//     outline: none;
//     &:active {
//         border: none;
//         outline: none;
//     }
// }
// .btn-cancel {
//     color: #3366FF;
//     text-transform: uppercase;
// }
// .btn-create,
// .btn-save,
// .btn-delete,
// .btn-convert {
//     padding: 0 20px;
//     height: 40px;
//     text-align: center;
//     background-color: #3366FF;
//     text-transform: uppercase;
//     border-radius: 4px;
//     color: #fff;
// }
// .btn-delete {
//     background-color: #FF3D71;
// }
// .btn-reservation {
//     height: 40px;
//     padding: 0 20px;
//     background-color: rgba(51, 102, 255, 0.08);
//     border: 1px solid #3366FF;
//     color: #3366FF;
// }
// .btn-convert {
//     background-color: darkgoldenrod;
// }
// .btn-adjustment {
//     color: #0095FF;
//     &:active {
//         box-shadow: none;
//     }
// }
// .btn-transfer {
//     color: #FFAA00;
//     &:active {
//         box-shadow: none;
//     }
// }
// .btn-request {
//     height: 40px;
//     padding: 0 20px;
//     background: rgba(255, 61, 113, 0.08);
//     color: #FF3D71;
//     border: 1px solid #FF3D71;
// }
// .btn-coming {
//     height: 40px;
//     padding: 0 20px;
//     background: rgba(0, 214, 143, 0.08);
//     color: #00D68F;
//     border: 1px solid #00D68F;
// }
// .btn-pencil {
//     transition: color 0.3s;
//     padding: 0;
//     &:hover {
//         nb-icon {
//             color: #3366FF !important;
//         }
//     }
//     &:active {
//         box-shadow: none;
//     }
// }
// .btn-close {
//     transition: color 0.3s;
//     &:hover {
//         nb-icon {
//             color: red !important;
//         }
//     }
//     &:active {
//         box-shadow: none;
//     }
// }
// .card {
//     min-width: 461px;
//     width: 100%;
//     background-color: #fff;
//     .card-header {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         background-color: #fff;
//         height: 57px;
//         font-family: 'Open Sans';
//         font-style: normal;
//         padding: 0 20px;
//         font-weight: 600;
//         font-size: 16px;
//         line-height: 22px;
//         color: #595D6E;
//         border-bottom: 1px solid #EDF0F9;
//     }
//     &__footer {
//         display: flex;
//         justify-content: flex-end;
//         align-items: center;
//         border-top: 1px solid #EDF0F9;
//         height: 56px;
//         padding-right: 20px;
//         .btn-cancel {
//             margin-right: 30px;
//         }
//         .btn-delete,
//         .btn-convert {
//             margin-right: 10px;
//         }
//     }
// }
// .card-content {
//     font-family: 'Open Sans';
//     font-style: normal;
//     padding: 5px 20px 20px;
//     .card-content__form {
//         padding-top: 7px;
//     }
//     &.card-content_review {
//         padding-bottom: 50px;
//     }
//     &__wrapper-text {
//         margin-bottom: 25px;
//         &:nth-last-child(1) {
//             margin-bottom: 0;
//         }
//     }
//     &__wrapper-input-field {
//         margin-bottom: 16px;
//         &:nth-last-child(1) {
//             margin-bottom: 0;
//         }
//         &--checkbox {
//             display: flex;
//             align-items: center;
//             margin-top: 20px;
//             margin-bottom: 16px;
//             .card-content__text {
//                 margin: 0;
//                 font-weight: 600;
//                 font-size: 13px;
//                 line-height: 24px;
//                 color: #222B45;
//             }
//         }
//     }
//     &__label {
//         display: block;
//         font-weight: bold;
//         font-size: 12px;
//         line-height: 16px;
//         text-transform: uppercase;
//         color: #8F9BB3;
//     }
//     &__input-field {
//         display: block;
//         width: 100%;
//         height: 48px;
//         padding-left: 16px;
//         padding-right: 12px;
//         font-size: 15px;
//         line-height: 20px;
//         background-color: #F7F9FC;
//         border: 1px solid #E4E9F2;
//         box-sizing: border-box;
//         border-radius: 4px;
//         margin-top: 8px;
//         transition: border 0.3s;
//         outline: none;
//         &:hover,
//         &:active,
//         &:focus {
//             border: 1px solid #598bff;
//         }
//         &::placeholder {
//             color: #8F9BB3;
//         }
//         &--data {
//             display: flex;
//             justify-content: space-between;
//             align-items: center;
//             color: #8F9BB3;
//             cursor: pointer;
//         }
//         &--checkbox {
//             width: 18px;
//             height: 18px;
//             margin-right: 8px;
//         }
//         &--select {
//             cursor: pointer;
//         }
//     }
//     &__text {
//         display: flex;
//         align-items: center;
//         margin-top: 9px;
//         font-weight: 600;
//         font-size: 15px;
//         line-height: 20px;
//         color: #222B45;
//         svg {
//             width: 18px;
//             height: 20px;
//             margin-right: 10px;
//         }
//         &--address {
//             display: flex;
//             align-items: flex-start;
//             margin-top: 6px;
//             font-weight: normal;
//             font-size: 12px;
//             line-height: 16px;
//             color: #8F9BB3;
//             nb-icon {
//                 margin-right: 8px;
//             }
//         }
//         &--shipment {
//             font-weight: 600;
//             font-size: 13px;
//             line-height: 24px;
//             color: #222B45;
//         }
//     }
// }
// .card-view {
//     input,
//     textarea,
//     label,
//     nb-select,
//     nb-checkbox {
//         cursor: default !important;
//     }
//     nb-select {
//         height: 20px !important;
//         button.select-button {
//             font-weight: 600 !important;
//             height: 20px !important;
//             padding: 0 !important;
//             background-color: #fff !important;
//             border: none !important;
//             color: #212529 !important;
//             nb-icon {
//                 z-index: -100;
//             }
//         }
//     }
//     textarea.card-content__input-field {
//         min-height: max-content;
//         font-weight: 600;
//     }
//     .card-content__input-field {
//         height: 20px;
//         background-color: #fff;
//         border: none;
//         padding: 0;
//         font-weight: 600;
//         &--data__text {
//             margin-left: 30px;
//         }
//         &--data {
//             position: relative;
//             nb-icon {
//                 position: absolute;
//                 top: -3px;
//             }
//         }
//     }
//     nb-checkbox {
//         cursor: inherit;
//         .custom-checkbox {
//             height: 0;
//             width: 0;
//             border: none;
//             z-index: -100;
//         }
//         span.text {
//             padding: 0 !important;
//             color: #212529 !important;
//         }
//     }
//     .nb-theme-default nb-checkbox .native-input:disabled~.text {
//         color: #222B45 !important;
//     }
//     .card__footer {
//         display: none !important;
//     }
// }
// textarea {
//     min-height: 110px;
//     padding: 14px 16px;
// }
// label {
//     cursor: pointer;
// }
// .card-content__select-wrapper {
//     position: relative;
//     &:before {
//         content: "";
//         background-image: url('./assets/images/arrow-ios-down.svg');
//         top: 12px;
//         right: 4px;
//         width: 29px;
//         height: 24px;
//         position: absolute;
//         background-color: #F7F9FC;
//     }
// }
// .hidden {
//     height: 0;
//     width: 0;
//     position: absolute;
//     left: 50%;
//     z-index: -1;
// }
// added-btn {
//     position: fixed;
//     top: 244px;
//     left: 0px;
//     z-index: 5;
//     border-bottom: 1px solid #edf1f7;
//     border-top: 1px solid #edf1f7;
// }
// .select-disabled {
//     nb-icon {
//         z-index: -100 !important;
//     }
// }
// .nb-theme-default nb-spinner {
//     background: #fff;
// }
// .card-header__buttons {
//     nb-icon {
//         color: #8F9BB3;
//     }
// }
// .card-content__input-field--text_error {
//     display: flex;
//     align-items: center;
//     margin-top: 5px;
//     font-weight: 600;
//     font-size: 12px;
//     line-height: 16px;
//     color: #FF3D71;
// }
// .input-error {
//     border: 1px solid #FF3D71;
// }
// .btn-shipment {
//     height: 24px;
//     text-align: center;
//     padding: 0 12px;
//     color: #fff;
//     font-style: normal;
//     font-weight: bold;
//     font-size: 10px;
//     line-height: 12px;
//     background-color: #3366FF;
//     border-radius: 4px;
// }
// //NewLayoutGSM
// nb-checkbox {
//     margin-left: 15px;
//     height: 16px;
//     .label {
//         padding: 0 !important;
//     }
//     span {
//         padding: 0 !important;
//     }
//     .custom-checkbox {
//         width: 16px !important;
//         height: 16px !important;
//     }
//     .custom-checkbox.checked {
//         background-color: #3366ff !important;
//         border-color: #3366ff !important;
//     }
//     .custom-checkbox {
//         background-color: #edf1f7 !important;
//         border-color: #e4e9f2 !important;
//     }
// }
// .arrow-ios-downward-outline {
//     transition: all 0.3s;
//     &__open {
//         transform: rotate(180deg);
//     }
// }
// .trash-2-outline {
//     margin: 0 15px;
//     transition: all 0.15s;
//     cursor: pointer;
//     &:hover {
//         color: red;
//     }
// }
// .graduse {
//     position: relative;
//     left: -6px;
//     svg {
//         position: relative;
//         width: 6px;
//         height: 6px;
//         top: -4px;
//         left: 4px;
//     }
//     &.modal-graduse {
//         svg path {
//             stroke: #8F9BB3;
//         }
//     }
// }
// .small_btn-modal {
//     height: 24px;
//     line-height: 24px;
//     width: max-content;
//     padding: 0 12px;
//     font-family: 'Open Sans';
//     font-weight: bold;
//     font-size: 10px;
//     color: #fff;
//     background-color: #3366FF;
//     border-radius: 4px;
//     transition: box-shadow 0.3s;
//     box-shadow: 0px 0px 0px #3366FF;
//     border: none;
//     &:focus {
//         outline: none;
//         box-shadow: 0px 0px 6px #3366FF;
//     }
// }
// .btn__grey-style {
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 16px;
//     text-transform: uppercase;
//     color: #8F9BB3;
// }
// .delete-icon {
//     color: #8F9BB3;
//     transition: color 0.15s;
//     cursor: pointer;
//     &:hover {
//         color: red;
//     }
// }
// .calendar-icon,
// .file-icon {
//     color: #8F9BB3;
//     transition: color 0.15s;
//     cursor: pointer;
//     &:hover {
//         color: #3366FF;
//     }
// }
// .cdk-drag-preview {
//     background-color: #edf1f7;
//     box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
// }
// .cdk-drag-placeholder {
//     opacity: 0;
// }
// .cdk-drag-animating {
//     transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
// }
// fraction-card {
//     position: relative;
// }
// .position-slider_block {
//     top: -60px;
//     position: absolute;
// }

/************************************/

.cdk-global-scrollblock {
    top: 1px !important;
}

a {
    text-decoration: none;
    cursor: pointer;
}

ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}

input,
textarea,
button {
    font-family: inherit;
}

button {
    cursor: pointer;
}

.mb30 {
    margin-bottom: 30px;
}

.content {
    padding: 4px;
    transition: all 0.3s;
    &.toggle-padding {
        margin-left: 70px;
    }
}

.main-content-container {
    background-color: #fff;
    padding: 24px 0 27px;
    // padding: 12px 0 27px;
}

.page-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 900;
    margin-bottom: 25px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: 600;
    padding-left: 27px;
    padding-bottom: 14px;
    padding-right: 20px;
    border-bottom: 1px solid #EDF0F9;
}

.sub-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #404145;
    margin-bottom: 15px;
}

.group-title {
    border: none;
    margin-top: 36px;
    margin-bottom: 0;
}

.service-wrapper {
    display: flex;
    margin-left: auto;
    align-items: center;
    flex-grow: 1;
    .saving-block {
        margin-right: auto;
    }
}

.buttons-group {
    display: flex;
    margin-left: auto;
}

.mb15 {
    margin-bottom: 15px;
}

.js-focus-visible {
    height: 100%;
}

.nb-theme-default {
    height: 100%;
}

.nb-theme-default nb-layout .layout {
    color: #404145;
}


/***** default-table ****/

table.default-table {
    color: #404145;
    tbody {
        cursor: pointer;
    }
    th {
        font-weight: normal;
        padding: 19px 21px 10px 22px;
        vertical-align: top;
    }
    th,
    td {
        height: 55px;
        text-align: left;
        border: 1px solid #EDF0F9;
    }
    td {
        a {
            display: block;
            min-height: 55px;
        }
    }
    td.icon-td {
        padding: 0;
        width: 58px;
        a {
            display: block;
            padding-top: 18px;
            padding-bottom: 17px;
            text-align: center;
            transition: all 0.5s;
            &:focus,
            &:hover {
                outline: none;
                rect {
                    fill: #B81D1D;
                }
            }
        }
    }
    tr {
        transition: all 0.3s;
    }
    .table-emty-row td {
        padding-top: 28px;
        padding-bottom: 27px;
    }
    margin-bottom: 26px;
}

table .default-table__input-row>td {
    background-color: #fff;
}

.nb-theme-default [nbInput].status-basic {
    background-color: transparent;
}

.table-hover tbody tr:hover,
.table-hover tbody tr:focus {
    color: #404145;
    background-color: #EDF1F7;
}

table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #F8F9FD;
    &:hover,
    &:focus {
        color: #404145;
        background-color: #EDF1F7;
    }
}

table .default-table__link {
    display: block;
    text-decoration: none;
    color: #404145;
    padding: 18px 5px 17px 21px;
    &:hover,
    &:focus {
        color: #404145;
        text-decoration: none;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

table .default-table__link-row td {
    padding: 0;
    .remove-link {
        display: block;
        // padding: 16px 0px 16px 19px;
        text-align: center;
    }
}

table .default-table__row {
    cursor: pointer;
}

table .default-table__row td {
    padding: 18px 5px 17px 21px;
    &.default-table__select-cell {
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

table.checking-account-table .default-table__row td {
    padding: 18px 5px 15px 21px;
    .default-table__link {
        padding: 0;
        display: inline-block;
    }
}

table td.default-table__input-cell {
    padding: 5px 7px;
    background-color: #fff;
    input.default-table__input {
        font-size: 0.9375rem;
        padding: 14px 5px 12px 13px;
        background-color: #fff;
    }
}

table .default-table__select-cell {
    padding: 0;
}

.td-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-wrapper {
    padding: 20px 19px 0;
    overflow-x: auto;
}

.two-column-table {
    th {
        width: 50%;
    }
    td {
        padding-left: 20px;
    }
}

.nb-theme-default a {
    font-size: 0.875rem;
}

.id-cloumn {
    width: 100px;
}

.comment-column {
    cursor: pointer;
}

.checkbox-column {
    max-width: 34px;
    padding: 0 !important;
    vertical-align: middle !important;
    text-align: center !important;
    min-width: 34px;
    width: 34px;
}


/**** tooltip ***/

.with-tooltip {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.nb-theme-default nb-tooltip {
    background: #101426;
    border: 0 dashed transparent;
    border-radius: 0.25rem;
    padding: 7px 12px;
    max-width: 358px;
    height: auto;
}

.nb-theme-default nb-tooltip .content {
    font-weight: normal;
    overflow-wrap: anywhere;
}


/**** end tooltip ***/

[ng-reflect-router-link] {
    outline: none
}


/***** end default-table ****/

.group-table th {
    // width: 337px;
    &.id-column {
        width: 100px;
    }
}


/******* form ********/
.form-container {
    display: flex;
    @media screen and (max-width: 1000px){
        flex-direction: column;
    }
}
.form-data {
    @media screen and (max-width: 1000px) {
        padding-top: 10px;
        padding-left: 26px;
    }
    width: 100%;
    &__title {
        display: flex;
        align-items: center;
        padding-top: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #222B45;
        margin-bottom: 16px;
    }
    &__subtitle {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #8F9BB3;
        margin-bottom: 4px;
        width: 100%;
    }
    &__data {
        white-space: pre-line;
        font-weight: 600;
        font-size: 13px;
        line-height: 24px;
        color: #222B45;
    }
}
.form-wrapper {
    max-width: 495px;
    width: 100%;
}
label.form__label {
    display: block;
    line-height: 16px !important;
    font-size: 12px !important;
    text-transform: uppercase;
}
.default-form.form {
    label.form__label {
        display: block;
        text-transform: uppercase;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-text-fill-color: #2E3A59;
        -webkit-box-shadow: inset 0 0 0 50px #F8F9FD;
    }
}

.form-control {
    height: 40px;
    &:focus {
        box-shadow: 0 0 0 0.2rem #EDF1F7;
    }
}

textarea {
    height: 104px;
    resize: none;
    background-color: #F8F9FD;
}

textarea.form__message {
    height: 104px;
    padding: 11px 20px;
    &::placeholder {
        color: #8F9BB3;
    }
}

.form-group__textarea {
    margin-bottom: 0;
}

.form-group:last-child {
    margin-bottom: 0;
}

.form-group {
    margin-bottom: 17px;

    &__description {
        margin-top: 8px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
    }
    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #404145;
        padding-bottom: 15px;
    }
}

.nb-theme-default [nbInput] {
    background-color: #F8F9FD;
}

.nb-theme-default [nbInput].size-medium {
    font-size: 0.8125rem;
    background-color: #F8F9FD;
    &::placeholder {
        font-size: 0.8125rem;
    }
}

.nb-theme-default [nbInput].size-medium.search-input {
    padding: 8px 5px 8px 46px;
}

.nb-theme-default [nbInput].shape-rectangle {
    border-radius: 4px;
}

.password-wrapper {
    position: relative;
    .visibility-password {
        width: 20px;
        height: 23px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 13px;
        background-image: url("./assets/images/directories/eye-off.svg");
        background-repeat: no-repeat;
        background-position: center;
        &.show {
            background-image: url("./assets/images/directories/eye.svg");
        }
    }
}

.two-column-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    magic-input, magic-date-input {
        width: 237px;
    }
    input {
        color: #8F9BB3;
    }
}

::-webkit-calendar-picker-indicator {
    color: transparent;
    opacity: 1;
    background: none;
}

.search-wrapper {
    position: relative;
    nb-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        color: #8F9BB3;
    }
    input.search-input {
        width: 249px;
        padding: 12px 5px 12px 46px;
        background-color: #F8F9FD;
        border: 1px solid rgba(143, 155, 179, 0.5);
    }
}

.form-padding {
    padding-top: 10px;
    padding-left: 26px;
    padding-right: 20px;
}


/***** buttons ******/

.nb-theme-default [nbButton] .remove-btn,
.nb-theme-default .back-btn {
    background-color: #fff;
}

.nb-theme-default [nbButton].appearance-outline.status-danger,
.nb-theme-default [nbButton].appearance-outline.status-primary.back-btn {
    background-color: #fff;
    border: none;
}

.nb-theme-default [nbButton].appearance-outline.status-warning,
.nb-theme-default [nbButton].appearance-outline.status-primary.back-btn {
    background-color: #fff;
    border: none;
}

.buttons-group button {
    height: 40px;
    margin-left: 12px;
}


/********** saving-block *************/

.saving-block {
    font-size: 12px;
    font-weight: 700;
    color: #8F9BB3;
    margin-left: 52px;
    margin-right: 51px;
}


/***** page-header *****/

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 25px;
}


/**** sidebar ***/

.nb-theme-default nb-layout-header.fixed~.layout-container nb-sidebar .main-container {
    height: 100vh;
}

.nb-theme-default nb-menu .menu-item>.menu-items {
    padding: 0;
}

.nb-theme-default nb-menu .menu-item>.menu-items>.menu-item a {
    border-left: 3px solid transparent;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    &.active,
    &:hover,
    &:focus {
        border-left: 3px solid #598bff;
    }
}

.nb-theme-default nb-menu .menu-item a {
    border-left: 3px solid transparent;
}

.nb-theme-default nb-menu .menu-item a.active,
.nb-theme-default nb-menu .menu-item a:hover {
    border-left: 3px solid #598bff;
}

.nb-theme-default nb-sidebar .scrollable {
    padding-bottom: 0;
}

.nb-theme-default nb-menu .menu-item .expand-state {
    transform: rotate(180deg);
}

.nav-menu {
    height: 100%;
    &>ul.menu-items {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
        &>li:last-child {
            margin-top: auto;
            margin-bottom: 30px;
        }
        nb-icon {
            min-width: 24px;
        }
    }
}


/**** ngx-access-select ****/

.nb-theme-default .options-list-container.appearance-outline.status-basic {
    //width: 100% !important;
}

.nb-theme-default nb-select.size-medium .select-button,
.nb-theme-default .size-medium nb-option {
    font-weight: 400;
}

.nb-theme-default nb-select.appearance-outline.size-medium .select-button {
    height: 40px;
}

.nb-theme-default nb-select.size-medium button {
    padding: 3px 33px 3px 11px !important;
    //width: auto;
    // border-radius: 16px !important;
}

.nb-theme-default .appearance-outline.size-medium nb-option-group .option-group-title,
.nb-theme-default .appearance-outline.size-medium nb-option {
    padding: 3px 33px 3px 11px !important;
}

.access-table {
    .nb-theme-default nb-select.appearance-outline .select-button nb-icon {
        color: #0D1C2E;
    }
}

.nb-theme-default .access-table nb-select.appearance-outline .select-button,
.nb-theme-default .rights-table nb-select.appearance-outline .select-button {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    color: #2E3A59;
    // background-color: transparent;
    border: none;
    border-radius: 16px;
}

.legal-entities-select {
    .select-button {
        font-weight: 600 !important;
        font-size: 13px;
        line-height: 24px;
        color: #222B45;
    }
}

.access-select-container {
    .select-button {
        nb-icon {
            color: currentColor !important;
        }
    }
}

.access-select--1 {
    .select-button {
        color: #8F9BB3 !important;
        background: rgba(143, 155, 179, 0.16) !important;
    }
}

.access-select--2 {
    .select-button {
        color: #0041A8 !important;
        background: rgba(0, 149, 255, 0.16) !important;
    }
}

// .nb-theme-default nb-select.appearance-outline.status-basicю .access-select--2 .select-button {
//     background: rgba(0, 149, 255, 0.16) !important;
// }
.access-select--3 {
    .select-button {
        color: #007D6C !important;
        background: rgba(0, 214, 143, 0.16) !important;
    }
}

.select-validation {
    border-color: #FFAA00 !important;
}

ngx-access-select {
    width: 100%;
}

.nb-theme-default .access-table nb-select.size-medium button nb-icon {
    right: auto !important;
    // left: 5.71rem !important;
}

.nb-theme-default .options-list-container {
    box-shadow: 0 0 1rem 0 rgba(44, 51, 73, 0.1);
}


/**** select  ***/

.nb-theme-default .options-list-container.appearance-outline {
    background-color: #fff;
}

@media (max-width: 1140px) {
    .title {
        flex-wrap: wrap;
        span {
            white-space: nowrap;
        }
    }
    .service-wrapper {
        margin-top: 20px;
        margin-left: 0;
    }
}

@media (max-width: 960px) {
    .search-wrapper {
        margin-right: auto;
    }
    .service-wrapper {
        flex-wrap: wrap;
        .saving-block {
            margin-left: 0;
        }
        .buttons-group {
            margin-left: 0;
        }
        .add-button {
            margin-left: auto;
            margin-top: 10px;
        }
    }
}


/****** provier bases-group*****/

.nb-theme-default .bases nb-card {
    box-shadow: none;
}

.nb-theme-default .bases nb-accordion {
    box-shadow: none;
}

[dir=ltr] .nb-theme-default .bases nb-accordion nb-accordion-item-header nb-icon {
    left: 1.25rem;
    right: auto;
    transform: rotate(-90deg);
}

.nb-theme-default nb-accordion nb-accordion-item-header {
    padding-left: 2.875rem;
}

.nb-theme-default nb-accordion nb-accordion-item-header {
    font-size: 0.875rem;
    line-height: 1.187;
    font-weight: 400;
    // border: none;
}

.nb-theme-default nb-accordion nb-accordion-item-body .item-body {
    padding: 0;
}

.nb-theme-default nb-list-item {
    padding-left: 3rem;
}

.nb-theme-default nb-list-item:first-child {
    border-top: 1px solid transparent;
}

.nb-theme-default nb-list-item:last-child {
    border-bottom: 1px solid transparent;
}

.nb-theme-default .form-bases [nbInput].size-medium {
    font-weight: 400;
    width: 180px;
}


/*********** map ************/

.location-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 5px 40px 0 28px;
}

.map-wrapper,
.map {
    max-width: 684px;
    width: 100%;
    margin-left: 63px;
    img {
        object-fit: contain;
        width: 100%;
    }
}

.mat-autocomplete-panel.mat-autocomplete-visible {
    border: 1px solid rgba(143, 155, 179, 0.5);
    border-radius: 4px;
    background: #FBFCFF;
    margin-top: -2px;
}
.mt_24 {
    margin-top: 24px;
}
agm-map {
    width: 100%;
    height: 100%;
}

.legal-entities-select {
    .nb-theme-default nb-select.appearance-outline.status-basic .select-button {
        font-weight: 600 !important;
    }
}
.modal-container {
    padding: 6px 19px 20px;
}
.overflow-container {
    height: auto;
    max-height: 498px;
    overflow-y: auto;
}
.nb-theme-default nb-spinner.status-primary {
    background-color: transparent;
}